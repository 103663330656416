document.addEventListener("DOMContentLoaded", function () {
  const toggleButton = document.getElementById("toggleButtonHeader");
  var visible = true;
  
  toggleButton.addEventListener("click", function () {
    console.log("hello");
    if (visible) {
      document.body.classList.add("no-scroll");
      visible = false;
    } else {
      document.body.classList.remove("no-scroll");
      visible = true;
    }
  });
});

jQuery(document).ready(function() {
  jQuery('.mobile-accordion-sub-nav').click(function(e) {
    e.preventDefault();
    jQuery(this).toggleClass('rotate180');
    jQuery('.sub-menu', this).toggle();
  });

  jQuery('.sub-menu').click(function(e) {
    e.stopPropagation();
  });
});