document.addEventListener('DOMContentLoaded', function() {
    var elem = document.querySelector('.main-carousel');
    var flkty = new Flickity( elem, {
        autoPlay: 4000,
        draggable: false,
        wrapAround: true,
        prevNextButtons: false,
        pauseAutoPlayOnHover: false,
        pageDots: false,
        contain: true
    });
});