document.addEventListener("DOMContentLoaded", () => {

    // if (document.querySelector("body.page-id-6")){
    //     let coverElement = document.querySelector('.wp-block-cover.alignfull.hero-banner');
    //     let imageElement = coverElement.querySelector('img');

    //     let images = [`/wp-content/uploads/2023/10/Freemont-St.png`, `/wp-content/uploads/2023/10/New-Building_NO_plane-1.png`];

    //     let picture = document.createElement('picture');
    //     picture.innerHTML=`
    //         <source srcset="${images[1]}" media="(min-width: 540px)">
    //         <source srcset="${images[0]}" media="">
    //         <img src="${images[0]}" alt="" class="wp-block-cover__image-background">
    //     `;
    //     coverElement.append(picture);
    // }

    if (document.querySelector("body.page-id-40")){
        let coverElement = document.querySelector('.wp-block-cover.alignfull.food-drink-hero');
        let imageElement = coverElement.querySelector('img');

        let images = [`/wp-content/uploads/2024/04/CNB_Mobile_Food_Drink.png`, `/wp-content/uploads/2024/04/CNB_Desktop_Food_Drink.png`];

        let picture = document.createElement('picture');
        picture.innerHTML=`
            <source srcset="${images[1]}" media="(min-width: 720px)">
            <source srcset="${images[0]}" media="">
            <img src="${images[0]}" alt="" class="wp-block-cover__image-background">
        `;
        coverElement.append(picture);
    }

    if (document.querySelector("body.page-id-42")){
        let coverElement = document.querySelector('.wp-block-cover.alignfull.parties-events-hero');
        let imageElement = coverElement.querySelector('img');

        let images = [`/wp-content/uploads/2024/03/CnB_Mobile_Parties.png`, `/wp-content/uploads/2024/03/CnB_Parties_Events.png`];

        let picture = document.createElement('picture');
        picture.innerHTML=`
            <source srcset="${images[1]}" media="(min-width: 720px)">
            <source srcset="${images[0]}" media="">
            <img src="${images[0]}" alt="" class="wp-block-cover__image-background">
        `;
        coverElement.append(picture);
    }

    if (document.querySelector("body.page-id-191")){
        let coverElement = document.querySelector('.wp-block-cover.alignfull.cnb-experience-hero');
        let imageElement = coverElement.querySelector('img');

        let images = [`/wp-content/uploads/2024/04/CNB_Mobile_Experience.png`, `/wp-content/uploads/2024/04/CNB_Desktop_Experience.png`];

        let picture = document.createElement('picture');
        picture.innerHTML=`
            <source srcset="${images[1]}" media="(min-width: 720px)">
            <source srcset="${images[0]}" media="">
            <img src="${images[0]}" alt="" class="wp-block-cover__image-background">
        `;
        coverElement.append(picture);
    }

    var i;
    var links = document.querySelectorAll('a[href="https://resy.com/cities/las/crash-n-burn"]');
    for (i = 0; i < links.length; i++) { links[i].addEventListener('click', function(e) {
    e.preventDefault();
    resyWidget.openModal({"venueId": 77606, "apiKey": "lZOkliDDt5m5TpGHh6eTyWJffu7uGUE2",
    'replace':'true'});
    }, false);
    }

});
